import type { IPage } from '../types/page'
import type { ComponentType } from 'react'
import dynamic from 'next/dynamic'
import { getPageProps } from '../api/page'

export const getServerSideProps = getPageProps

const Page404: ComponentType = dynamic(() => import('./404').then((module) => module.Page404))
const Page500: ComponentType = dynamic(() => import('./500').then((module) => module.default))
const PageTemplate: ComponentType<IPage> = dynamic(() =>
  import('../components/templates/PageTemplate').then((module) => module.PageTemplate),
)

const Page = (data: IPage): JSX.Element => {
  if (data.is500) {
    return <Page500 />
  }

  if (!data.page?.data?.length) {
    return <Page404 />
  }

  return <PageTemplate {...data} />
}

export default Page
